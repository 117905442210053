import { Card } from '@blueprintjs/core'
import { useDarkTheme } from './useDarkTheme'
import apps from './apps.json'

function App() {
  const isDarkMode = useDarkTheme()

  return (
    <main className={`${isDarkMode ? 'bp5-dark' : ''}`}>
      {apps.map((app) => (
        <Card
          key={app.href}
          className={`app bg-${app.bg}`}
          interactive
          tabIndex={0}
          role="button"
          onClick={() => window.location.assign(app.href)}
        >
          <h2>{app.title}</h2>
          <p>{app.description}</p>
        </Card>
      ))}
    </main>
  )
}

export default App
